.pergunta {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-around;
    
}

.container-texto-linha {
    padding: 10px;
    box-shadow: 
    -43px -43px 0 -40px whitesmoke,
    43px 43px 0 -40px whitesmoke;
}


.container-texto {
    display: flex;
    justify-content: center;
    align-items: center; 
    min-height: 120px;
    padding: 5px;
    background-color: white;
    border-radius: 0 24px 0 24px ;
}

.botoes-respostas {
    height: 20vh;
}

.pergunta button {
    border-radius: 150px;
    font-weight: 800;
    font-size: 19px;
    padding: 17px;
    /* margin-top: 18px; */
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.button-yes {
    border: 2px solid white;
    margin-bottom: 15px;
    background-color: hsl(138, 44%, 41%);
    color: white;
    -webkit-box-shadow: 10px 15px 34px -3px rgba(27, 225, 27, 0.5);
    -moz-box-shadow: 10px 15px 34px -3px rgba(27, 225, 27, 0.5);
    box-shadow: 10px 15px 34px -3px rgba(27, 225, 27, 0.4);
    cursor: pointer;
}


.button-no {
    border: 2px solid white;
    border-color: white;
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: rgb(200, 57, 57);
    color: white;
    -webkit-box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    -moz-box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    cursor: pointer;
}



.button-back {
    border: 2px solid white;
    margin-top: 15px;
    -webkit-box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    -moz-box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    color: white;
    background-color: gray;
    cursor: pointer;
}