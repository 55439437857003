.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App img {
  margin-top: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
}