.pagina-amarela {
    margin-top: 50px;
    height: 180vh;
    width: auto ;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: #faca0a;
    border-radius: 35px 35px 0 0 ;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    -webkit-box-shadow: 0px -18px 35px 3px rgba(23,23,23,0.29);
    -moz-box-shadow: 0px -18px 35px 3px rgba(23,23,23,0.29);
    box-shadow: 0px -18px 35px 3px rgba(23,23,23,0.29);
}

.pagina-amarela h1 {
    text-align: center;
    color: #1e1e1e;
}

.pagina-amarela p {
    font-size: 18px;
    color: #1e1e1e;
    text-align: center;
    padding-left: 45px;
    padding-right: 45px;
}

.pagina-amarela button {
    border-radius: 150px;
    font-weight: 800;
    font-size: 19px;
    padding: 17px;
    /* margin-top: 18px; */
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
    -webkit-box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    -moz-box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    box-shadow: 4px 10px 24px -8px rgba(89,89,89,0.56);
    color: white;
    background-color: gray;
    cursor: pointer;
}


.icones {
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}

.star-complete {
    padding-right: 5px;
    padding-left: 5px;
    color: #1e1e1e;
    font-size: 70px;
    
}

.star-incomplete {
    padding-right: 5px;
    padding-left: 5px;
    color: #1e1e1e;
    font-size: 70px;
}


@media only screen and (min-width: 600px) {
    .pagina-amarela {
        margin-top: 50px;
        height: 160vh;
        width: auto ;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-color: #faca0a;
        border-radius: 35px 35px 0 0 ;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        -webkit-box-shadow: 0px -18px 35px 3px rgba(23,23,23,0.29);
        -moz-box-shadow: 0px -18px 35px 3px rgba(23,23,23,0.29);
        box-shadow: 0px -18px 35px 3px rgba(23,23,23,0.29);
    }

    .pagina-amarela p {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
        font-size: 17px;
        color: #1e1e1e;
        text-align: center;
        padding-left: 45px;
        padding-right: 45px;
    }
    
}