@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


.title-page h1{
  text-align: center;
  font-size: 27px;
  font-weight: 800;
  color: whitesmoke;
  margin-top: 36px;
  margin-bottom: 36px;
}


.inicial h2 {
    color: white;
    font-weight: 400;
    font-size: 1rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-bottom: 0.4rem;
    display: block;
    margin-right: auto;
    text-align: left;
}

.inicial {
    margin-top: 2rem;
}

.div-texto {
  border-left: 5px solid white;
  margin-bottom: 25px;
  width: 300px ;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.pushable {
    display: block;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
    position: relative;
    background: transparent;
    padding: 0px;
    border: none;
    cursor: pointer;
    outline-offset: 4px;
    outline-color: green;
    transition: filter 250ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    

  }
  
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: greenyellow;
    border-radius: 8px;
    filter: blur(2px);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
    -webkit-box-shadow: 10px 15px 34px -3px rgba(27, 225, 27, 0.5);
    -moz-box-shadow: 10px 15px 34px -3px rgba(27, 225, 27, 0.5);
    box-shadow: 10px 15px 34px -3px rgba(27, 225, 27, 0.5);
    animation: pulse 1s infinite;
  }
  
  .edge {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(
      to right,
      hsl(110, 100%, 39%) 0%,
      hsl(110, 100%, 49%) 8%,
      hsl(110, 100%, 39%) 92%,
      hsl(110, 100%, 29%) 100%

/* 
      hsl(248, 39%, 39%) 0%,
      hsl(248, 39%, 49%) 8%,
      hsl(248, 39%, 39%) 92%,
      hsl(248, 39%, 29%) 100% */
    );
  }
  
  .front {
    display: block;
    position: relative;
    border-radius: 8px;
    background: hsl(138, 44%, 41%) 100%;
    padding: 16px 32px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1rem;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }
  
  .pushable:hover {
    filter: brightness(110%);
  }
  
  .pushable:hover .front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  
  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  
  .pushable:hover .shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  
  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
  

.captura-leads {
  flex-direction: column;
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}

.captura-leads h1 {
  margin-top: 40px;
  color: white;
  font-size: 23px;
  text-align: center;
}


.forms-lead {
  display: flex;
  flex-direction: column;
  width: 300px;
  
}


.forms-lead button {
  color: white;
  font-size: 18px;
  font-weight: 600;
  background-color: hsl(138, 44%, 41%);
  border-color: transparent;
  height: 50px;
  border-radius: 13px;
  margin-top: 20px;
  cursor: pointer;
}

.forms-lead input {
  padding-left: 20px;
  height: 40px;
  margin-top: 20px;
  border-radius: 13px;
  font-weight: 500;
  font-size: 15px;
  color: #1e1e1e;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 .4vw rgba(0,0,0,0.5), 0 0 0 3px transparent;
  border: none;
  outline: none;
  padding-left: 20px;
  transition: .4s;
}


.forms-lead input:hover {
  box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.186);
}

.forms-lead input:focus {
  box-shadow: 0 0 0 2px hsl(138, 44%, 41%);
}