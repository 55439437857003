.quiz { 
    height: 60vh;
    width: 300px ;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.title-quiz { 
    height: 60vh;
    width: 300px ;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    
}

.title-quiz h1 {
    animation: 1s ease-in 0s 1 fadeIn;
    padding-left: 15px;
    border-left: 4px solid whitesmoke;
    text-align: left;
    color: white;
    font-size: 30px;
    font-weight: 600;
}

.title-quiz button {
    animation: 1s ease-in 0s 1 fadeIn;
    border: 1px green solid;
    cursor: pointer;
    width: 300px;
    height: 7vh;
    background-color: white;
    border-radius: 150px;
    display: block;
    margin-left: auto;
    margin-left: auto;
    margin-top: 60px;
}

.title-quiz span {
    color: darkred;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}


.quiz h1 {
    color: white;
    text-align: center;
    font-size: 25px;
}

.container-bar {
    height: 10px;
    background-color: white;
    border-radius: 20px;
    border: 2px solid white;
}

.progresso-barra {
    height: 10px;
    background-color: hsl(138, 44%, 41%);
    border-radius: 20px;
}
